<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    default: 'Chargement en cours...',
  },
});
</script>
<template>
  <div class="base-loader flex flex-col items-center justify-center">
    <BaseIcon size="50" icon="eos-icons:loading" />
    {{ props.text }}
  </div>
</template>
